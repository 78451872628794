export enum Order {
  ASC = 'asc',
  DESC = 'desc',
}

export enum AccountStatus {
  MOCK = 'MOCK',
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  AWAITING_APPROVAL = 'AWAITING_APPROVAL',
  TO_BE_SIGNED = 'TO_BE_SIGNED',
}

export enum ActiveStatus {
  LOCKED = 'LOCKED', // WOOD says the client is not dead and still have an existing contract, but is locked for some reason
  CLIENT_DECEASED = 'CLIENT_DECEASED', // WOOD says the client has deceased
  IN_LIQUIDATION = 'IN_LIQUIDATION', // client is active, but account portfolios are in liquidation process
  CLOSED = 'CLOSED', // client is active, but account portfolios are liquidated
  IN_TERMINATION = 'IN_TERMINATION', // client is in the process of contract termination
  CONTRACT_TERMINATED = 'CONTRACT_TERMINATED' // client is not active anymore as the client contract was terminated
}

export enum SignatureStatus {
  ACTIVE = 'ACTIVE',
  SIGNED = 'SIGNED',
  EXPIRED = 'EXPIRED'
}

export enum PersonType {
  PHYSICAL = 'PHYSICAL',
  LEGAL = 'LEGAL',
  NONE = ''
}

export enum AccountMode {
  STANDARD = 'STANDARD',
  LTIP = 'LTIP'
}

export enum UserRoles {
  EDW_BACKOFFICE = 'core:backoffice:access',
  EDW_ADMIN = 'core:admin:access',
  ADVISOR = 'core:basic:access',
  ACCOUNT_CREATE_NEW = 'core:account:new',
  ACCOUNT_CREATE_PHYSICAL = 'core:account_physical:create',
  ACCOUNT_CREATE_LEGAL = 'core:account_legal:create',
  ACCOUNT_REQUEST_SIGN = 'core:account:request_sign',
  ACCOUNT_DRAFT_REMOVE = 'core:account:draft_remove',
  ACCOUNT_REBALANCING = 'core:account:rebalancing',
  ACCOUNT_HEDGING = 'core:account:currency_hedging',
  ACCOUNT_IND_PORTFOLIO = 'core:account:ind_portfolio',
  ACCOUNT_CURRENCY_EUR = 'core:account:currency_eur',
  ACCOUNT_EXT_PRODUCT = 'core:account:ext_product',

  USER_SIGN = 'client_portal:sign:access',
  USER_BASIC = 'client_portal:basic:access',
  USER_FULL = 'client_portal:full:access',
}

export enum Client {
  CLIENT_PORTAL_SIGN = 'client_portal_sign',
  CLIENT_PORTAL= 'client_portal'
}

export enum EdwardColors {
  DARK = 'rgba(255, 203, 19, 1)',
  DARKER = 'rgba(255, 212, 78, 1)',
  MEDIUM = 'rgba(255, 221, 127, 1)',
  LIGHTER = 'rgba(255, 232, 167, 1)',
  LIGHT = 'rgba(255, 243, 210, 1)',

  SECONDARY = 'rgba(186, 130, 53, 1)',

  BLUE = 'rgba(47, 160, 209, 1)',

  GREEN = 'rgba(82, 199, 0, 1)',
  GREEN_LIGHTER = 'rgba(144, 237, 125, 1)',
  GREEN_LIGHT = 'rgba(188,252,204,1)',
  GREEN_MEDIUM = 'rgba(131,213,74,1)',

  NEGATIVE = '#000',
  POSITIVE = 'rgba(82, 199, 0, 1)',
  WARNING = '#ff6600',
  ALERT = '#ff0000',

  GRAY_DARKER = 'rgba(88, 88, 88, 1)',
  GRAY_MEDIUM = 'rgba(130, 130, 130, 1)',
  GRAY_LIGHTER = 'rgba(189, 189, 189, 1)',
  GRAY_LIGHT = 'rgba(210, 210, 210, 1)',
  GRAY_EXTRALIGHT = 'rgba(245, 245, 245, 1)',

}

export enum ClientView {
  BASIC = 'BASIC',
  FULL = 'FULL',
  NONE = 'NONE'
}

export enum Method2FA {
  OTP = 'OTP',
  SMS = 'SMS',
  NONE = ''
}

export enum Currency {
  CZK = 'CZK',
  EUR = 'EUR',
  USD = 'USD',
  NONE = '',
  OTHER = 'OTHER',
  UNKNOWN = 'UNKNOWN'
}
